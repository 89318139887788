import { Component, OnInit } from '@angular/core';
import { IdlerService } from '@Shared/components/idler/idler.service';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { RouterOutlet } from '@angular/router';

@Component({
  templateUrl: './dashboard-case.component.html',
  styleUrls: ['./dashboard-case.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class DashboardCaseComponent implements OnInit {
  constructor(
    public idleService: IdlerService,
    public authService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    environment.logReq = true;
    this.idleService.dispatchStart();
    this.idleService.addOnIdleFunction(() => {
      this.authService.logout();
    });
  }
  ngOnDestroy(): void {
    this.idleService.dispatchStop();
    environment.logReq = false;
  }
}
